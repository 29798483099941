import * as React from 'react'
import KnowledgeBaseLayout from "../layouts/KnowledgeBaseLayout";
import {useLanguage} from "../hooks/hooks";

const Status : React.FunctionComponent = () => {
  const lang = useLanguage()

  const status = lang == 'nl' ? (
    <p>Geen problemen bekend</p>
  ) : (
    <p>Currently no issues</p>
  )

  return (
    <KnowledgeBaseLayout leftBar={false}>
      <h1>Status</h1>
      { status }
    </KnowledgeBaseLayout>
  )
}

export default Status
